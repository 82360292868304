//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import AuthContext from "../../contexts/AuthContext";
import { bConst} from "../../constants";
import Cookies from 'js-cookie';

import {
  Alert as MuiAlert,
  Typography,
  Button,
  TextField as MuiTextField,
} from "@mui/material";

import {
  Login, 
} from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

export function SignIn() {
  const { login, errorMsg } = React.useContext(AuthContext); 
  const [account, setAccount] = React.useState(Cookies.get(bConst.bloqsAccount));
  const [username, setUsername] = React.useState(Cookies.get(bConst.bloqsUserName));
  const [password, setPassword] = React.useState("");
  
  if (username===undefined) { setUsername('') }

  const loginUser = React.useCallback( async (event) => {
    event.preventDefault();
    await login(account, username, password);  
  })      

  return (
     <form noValidate onSubmit={loginUser}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Login om door te gaan....
      </Typography>

      { errorMsg && 
        <Alert mt={2} mb={3} severity="warning">
          {errorMsg}
        </Alert>
      }
  
      <TextField
        type="text"
        name="account"
        label="Account"
        value={account}
        required
        fullWidth
        onChange={(e) => setAccount(e.target.value)}
        my={2}
      />

      <TextField
        type="text"
        name="userName"
        label="Gebruiker"
        autoFocus={username===''}
        required
        value={username}
        fullWidth
        onChange={(e) => setUsername(e.target.value)}
        my={2}
      />

      <TextField
        type="password"
        name="password"
        label="Wachtwoord"
        autoFocus={username!==''}
        value={password}
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
        my={2}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<Login />}
        onClick={loginUser}>
        Inloggen
      </Button> 
   
    </form> 
  )
}

export default SignIn;
