//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import * as React from "react";
import styled from "styled-components/macro";
import { versionStr } from "../../src/constants";
import AuthContext from "../contexts/AuthContext";


import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  const { user } = React.useContext(AuthContext);
  let footerText ='bloqs clienten-portaal'
  if (user.isMedw===1) { footerText='bloqs WebApp'}
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden mdDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItemButton component="a" href="https://www.bloqssoftware.nl">
                <ListItemText primary={footerText} />
              </ListItemButton>
            </List>
          </Grid>
        </Hidden>

        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton component="a" href="https://www.bloqssoftware.nl">
              <ListItemText
                primary={`${new Date().getFullYear()} - bloqs Software BV - `+versionStr}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
