//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loader;
