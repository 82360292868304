//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  max-width: 400px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const loginLayout = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default loginLayout;
