//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}


import React from 'react';

export const useSessionStorage = () => {

    const isLocalSessionAvailable = () => {
        try {
            const val = "Dit is voor test";
            window.sessionStorage.setItem("___test", val);
            let res = window.sessionStorage.getItem("___test");
            window.sessionStorage.removeItem("___test");
            if( res === val ) return true;
            return false;
        } catch(err) {
            return false;
        }
    }
    
    const fetch = React.useCallback( (itemname, fallback = "") => {
        try {
            if( ! isLocalSessionAvailable() ) {
                return;
            }
            return window.sessionStorage.getItem(itemname) || fallback;
        } catch(err) {
            console.error( 'useSessionStorage.fetch', err );
            return fallback || "";
        }
    }, []);

    const save = React.useCallback( (itemname, value) => {
        try {
            if( ! isLocalSessionAvailable() ) {
                return;
            }
            window.sessionStorage.setItem(itemname, value);
            return true;
        } catch(err) {
            console.error( 'useSessionStorage.save', err );
            return false
        }
    }, []);

    const remove = React.useCallback( (itemname) => {
        try {
            if( ! isLocalSessionAvailable() ) {
                return;
            }
            window.sessionStorage.removeItem(itemname);
            return true;
        } catch(err) {
            console.error( 'useSessionStorage.remove', err );
            return false;
        }
    }, [])

    return {
        fetch,
        save, 
        remove
    }
}
export default useSessionStorage;