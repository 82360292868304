//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export let versionStr = '' 
export let buildNr = '5'

export function setVersionStr(str) {
  versionStr = 'Versie '+str
}

// bloqs constanten
export const bConst = {
  bloqs_WebSite: 'https://www.bloqssoftware.nl/',

  // session names
  bloqsAccount  :'hv6MYAMR8ZBfHKz5',
  bloqsUserName :'RQCmRryB6LvaoXng',
  accessToken   :'LNrCwpLr6Gb9ajGa',
  refreshToken  :'zAsYFbSYUe23XhYd',
  accountKey    :'QcLhH7a3gTzFcwHv',
  tfaVerified   :'ToTBcuqHJJMsNth7',

  // session values
  tfaValue      :'E4dJCxMkRCoVfo2AvfyPs56NSGVUEAoFo7srdJpPW787LEBi',

  rootLocation  :'/clients',

  //status
  s_Success:             0,
  s_UserNotFound:        1,
  s_TokenExpired:        2,
  s_AccountNotFound:     3,
  s_ServerError:         4,
  s_NotAuthenticated:    5,
  s_IsGedeclareerd:      6,
  s_QueryError:          7,
  s_ParamError:          8,
  s_NotFound:            9,
  s_UnknownError:        99,

  //product
  zp_OZL:     0,
  zp_OMD:     6,
  zp_Con:     1,
  zp_Job:     2,
  zp_Alg:     7,
  zp_UWV:     3,
  zp_UWVJob:  5,
  zp_PPT:     4,
  zp_None:    99,
  
  // status dbase Query
  db_Undefined: undefined,
  db_Loading:   1,
  db_Loaded:    2,
  
  // 
  nrLastClients: 10,
  IdleTimeOut:   600, // timeout time in seconds

   // logboekform  tabs
  lb_tabReg:  0,
  lb_tabDoel: 1,
  lb_tabDoc:  2,
   
  // logboek declaratie
  lb_NotDecl:    0,
  lb_Decl:       1,
  lb_BlockDecl:  2,
  
  // Editwindow action
  ed_NoShow:     0,
  ed_Show:       1,
  ed_Edit:       2,
  
  // Kosten vergoeding status
  kv_Open:      0,
  kv_Akkoord:   1,
  kv_Afgekeurd: 2,
  
  // Kosten vergoeding tabs
  kv_tabDecl:   0,
  kv_tabDoc:    1,

  //Rechten (relatie met database rechten)
  r_MngtMenu:          1,
  r_OwnNotes:          2,
  r_OtherNotes:        3,
  r_Opdr:              4,
  r_Wg:                5,
  r_Fact:              6,
  r_Persoonlijk:       7,
  r_PersPlanning:      8,
  r_HR:                9,
//   vrij:               10,
  r_MngtClients:       11,
  r_AlgMngt:           12,
  r_lbShowAll:         13,
  r_lbMngtAll:         14,
  r_lbMngt:            15,
  r_WMOMngtOnderst:    16,
  r_WMOMngtInd:        17,
  r_WMOMngtBericht:    18,
  r_VerzoekVullen:     19,
  r_VerzoekAcc:        20,
  r_VerzoekGen:        21,
  r_MngtDecl:          22,
//   vrij:               23,
  r_ConMngtInd:        24,
  r_ConMngt:           25,
  r_JobMngt:           26,
  r_JobMngtTraject:    27,
  r_JobFactGen:        28,
  r_UWVMngt:           29,
  r_UWVJobMngtRes:     30,
  r_UWVJobMngtBeoord:  31,
  r_UWVJobMngtJCP:     32,
  r_WMOHerind:         33,
  r_KMSMngt:           34,
  r_lbShowFunct:       35,
  r_AdminMngt:         36,
  r_FormMngt:          37,
//   vrij:               38,
  r_ContractMngt:      39,
  r_Schrijfrecht:      40,
  r_InCaseload:        41,
  r_Rechten:           42,
//   vrij:               43,
//   vrij:               44,
//   vrij:               45,
//   vrij:               46,
//   vrij:               47,
//   vrij:               48,
  r_MediaMngt:         49,
  r_AllClientsOZL:     50,
  r_AllClientsOMD:     51,
  r_AllClientsCon:     52,
  r_AllClientsJob:     53,
  r_AllClientsAlg:     54,
  r_AllClientsUWV:     55,
  r_AllClientsUWVJob:  56,
  r_AllClientsPPT:     57,

  eh_Min:     0,
  eh_Uren:    1,
  eh_Dagdeel: 2,
  eh_Etmaal:  3,
  eh_Week:    4,

// Doc Source
  ds_Client:       0,
  ds_Medw:         1,
  ds_KMS:          2,
  ds_WMO:          3,
  ds_Bib:          4,
  ds_Project:      5,
  ds_Werkgever:    6,
  ds_WgTraject:    7,
  ds_Opdrachtgever:8,
  ds_Incident:     9,
  ds_Upload:       10,
  ds_None:         99,

  // Doc Master type
  dm_NoMaster:      0,
  dm_WMOLogboek:    1,  
  dm_OnderstPlan:   2,
  dm_UWV:           3,
  dm_WMO:           4,
  dm_Indicatie:     5,
  dm_Functie:       6,
  dm_ClientEmail:   7,
  dm_UWVJobP:       8,
  dm_UWVJobC:       9,
  dm_Contract:      10,
  dm_UWVJob:        11,
  dm_Beoordeling:   12,
  dm_PPT:           13,
  dm_Logboek:       14,
  dm_ActLogboek:    15,
  dm_FactTemp:      16,
  dm_Medw:          17,
  dm_Alg:           18,
  dm_Opdr:          19,
  dm_WG:            20,
  dm_MwGesprek:     21,
  dm_Incident:      22,
  dm_BEMTemp:       23,

  // Doc Detail Client
  dd_OZL:      0,
  dd_Con:      1,
  dd_Job:      2,
  dd_UWV:      3,
  dd_WMO:      4,
  dd_PPT:      5,
  dd_UWVJob:   6,
  dd_OMD:      7,
  dd_Alg:      8,
  dd_Opl:      9,
  dd_Opdr:     10,
  dd_Wg:       11,
  dd_Medw:     12,
  dd_Incident: 13,
  dd_MwKosten: 14,
  dd_MwKm:     15,
  dd_None:     99,

  // Doc Area
  da_Zorg:      0,
  da_PPT:       1,
  da_Arbeid:    2,
  da_Fin:       3,
  da_Verz:      4,
  da_Studie:    5,
  da_Funct:     6,
  da_Wg:        7,
  da_UWV:       8,
  da_WMO:       9,
  da_Functie:   10,
  da_UWVJob:    11,
  da_Contract:  12,
  da_Factuur:   13,
  da_Alg:       14,
  da_Medw:      15,
  da_Opdr:      16,
  da_MwGesprek: 17,
  da_Incident:  18,  
  da_None:      99,

  // doc cat
  dc_NoCat:    999,

  // Doc prefix
  dp_Cl:       'Cl',

  // Clienten Portaal item
  cp_Doc:       0,
  cp_Afs:       1,
  cp_Taken:     2,
  cp_Upload:    3,

  // Doc Clienten Portaal
  dcp_Not:      0,
  dcp_Read:     1,
  dcp_Sign:     2,  // Doc
  dcp_Confirm:  2,  // Afs
  dcp_Ready:    2,  // Taken
  dcp_Upload:   3,

  // rechten ClientPortaal
  rcp_DocUpload:   2,
  rcp_AfsBevestig: 2,

  // ClientPortaal item status
  cpi_Init:    0,
  cpi_noted:   1,
  cpi_remind:  2,
  cpi_Red:     2,
  cpi_Done:    3,
  cpi_End:     10,

// status two factor authentication
  tfa_Not:      0,
  tfa_NoKey:    1,
  tfa_HasKey:   2,

// Colors
  lightBlue:  "#e4f1fc",
  lightBlue1: "#d5eafb",
  lightBlue2: "#b3d8f7",
  lightGreen: "#e9f7f4",

};

// bloqs messages
export const bMess = [];
bMess[bConst.s_Success]           = "Ok"; 
bMess[bConst.s_UserNotFound]      = "Gebruiker/wachtwoord niet gevonden."; 
bMess[bConst.s_TokenExpired]      = "Token is verlopen."; 
bMess[bConst.s_AccountNotFound]   = "Account niet gevonden."; 
bMess[bConst.s_ServerError]       = "Er is een serverfout opgetreden."; 
bMess[bConst.s_IsGedeclareerd]    = "Registratie is gedeclareerd."; 
bMess[bConst.s_NotAuthenticated]  = "De gebruiker is niet geauthoriseerd."; 
bMess[bConst.s_QueryError]        = "Er is een fout opgetreden in de database query."; 
bMess[bConst.s_ParamError]        = "Er is een fout opgetreden in de endpoint parameters."; 
bMess[bConst.s_UnknownError]      = "Er is een onbekende fout opgetreden."; 
bMess[bConst.s_NotFound]          = "De gegevens zijn niet gevonden."; 


