//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

const useTheme = () => useContext(ThemeContext);

export default useTheme;
